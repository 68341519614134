<template>
  <div>
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting"  class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">数据统计分析</span>
      </div>
    </div> -->
   <a-spin :spinning="loading" tip="数据获取中，请稍候......" >
    <div class="table-search">
      <a-form-model size="small" layout="inline" label-width="68px">
        <a-form-model-item label="样品类型" prop="sampleType">
          <a-select v-model="typeValue" placeholder="请选择样品类型" show-search :allowClear="true">
            <a-select-option key="0" value="0">条纹相</a-select-option>
            <a-select-option key="1" value="1">三氧化二铝棒状</a-select-option>
            <a-select-option key="2" value="2">三氧化二铝孔隙率</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="催化剂类型：" prop="sampleNumberArr">
          <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">
            <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="样品编号：" prop="sampleNumberArr">
          <a-select style="width:200px;" placeholder="请选择样品编号" mode="multiple" :maxTagCount="1" @change="handleChange"
          @dropdownVisibleChange="handleDropdownVisibleChange">
            <a-select-option v-for="item in files" :key="item.id" :value="item.id" :title="item.value">{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="识别信息：" prop="sampleNumberArr">
          <a-select v-model:value="resultValue" style="width:320px;" placeholder="请选择实验信息" show-search :allowClear="true">
            <a-select-option v-for="item in resultInfos" :key="item.id" :value="item.id" :title="item.value">{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" icon="search" v-hasPermi="['analysis_search']" style="margin: 0 10px" @click="search">查询</a-button>
          <a-button icon="retweet" style="margin: 0 10px" @click="reset">重置</a-button>
          <a-button type="primary" icon="fund" v-hasPermi="['analysis_statistics']" style="margin: 0 10px" @click="analysis">统计分析</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <ImageList ref="eleImageList" v-show="showImage" :imageFiles="imageFiles" :fullWidth="fullWidth"/>
    <div class="chartDiv" v-if="showChart">
      <CatalystChart ref="eleChartLength" v-show="showLength" :chartWidth="halfWidth" :chartOption="chartLengthOption"/>
      <CatalystChart ref="eleChartLayer" v-show="showLayer" :chartWidth="halfWidth" :chartOption="chartLayerOption"/>
      <CatalystChart ref="eleChartNumber" v-show="showNumber" :chartWidth="halfWidth" :chartOption="chartNumberOption"/>

      <div class="file-group" v-show="showGroup"  :style="{ width : halfWidth+'px' }">
        <a-card title="操作面板" >
          <table>
            <tr>
              <th>选择</th>
              <th>显示名称</th>
              <th>操作</th>
            </tr>
            <tr v-for="(value,key) in this.fileGroupDatas" :key="value[0]">
              <td>{{ value[0] }}</td>
              <td>
                <a-input ref="{{value[0]}}" v-model="value[1]" @keydown.enter="handelUpdateFileGroup(value[0],value[1])"/>
              </td>
              <td>
                <a @click="handelUpdateFileGroup(value[0],value[1])">更新</a>
                <a @click="handelDeleteFileGroup(value[0])">删除</a>
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
          </table>
        </a-card>
      </div>
    </div>
  </a-spin>
  </div>

</template>


<script lang="ts">
import {Modal} from 'ant-design-vue';

import {getCatalystsData, getFilesData, getImageFiles, getAnalysisData,getResultInfoData} from "../utils/data-manager";
import ImageList from './ImageListMul';
import CatalystChart from './CatalystChart';

export default {
  components: {
    ImageList, CatalystChart
  },
  data() {
    return {
      loading:false,
      showImage: false,
      showChart: false,
      startValue: null,
      endValue: null,
      endOpen: false,
      catalysts: [],
      catalystValue: null,
      files: [],
      fileValue: null,
      imageFiles: [],
      resultInfos: [],
      resultValue: null,
      typeValue: null,
      oldTypeValue:null,
      // fullWidth: document.documentElement.clientWidth - 200,
      // halfWidth:Math.ceil((document.documentElement.clientWidth - 320) / 2),
      chartLengthOption: null,
      chartLayerOption: null,
      chartNumberOption: null,
      selectionTimes: 0,
      fileGroupDatas: null,
      showLength:true,
      showLayer:true,
      showNumber:true,
      showGroup:true
    };
  },
  computed: {
    fullWidth() {
      return document.documentElement.clientWidth - 200;
    },
    halfWidth() {
      return Math.ceil((document.documentElement.clientWidth - 320) / 2);
    }
  },
  async created() {
    this.catalysts = await getCatalystsData();
    this.resultInfos = await getResultInfoData();
  },
  methods: {
    reset(){ //清除统计图表数据
      this.showChart=false;
      this.chartLengthOption=null;
      this.chartLayerOption=null;
      this.chartNumberOption=null;
      this.selectionTimes=0;
      this.fileGroupDatas=null;
    },
    async search() {
      if(this.fileValue&&this.fileValue.length>0){
        this.loading=true;
        this.imageFiles = await getImageFiles(this.catalystValue, this.startValue, this.endValue, this.fileValue,this.resultValue,this.typeValue);
        this.loading=false;
        this.showImage = true;
        //查询时，根据选择的样品类型，显示各个统计图，如果切换了样品类型，重置数据；
        if(this.typeValue==1){//样品类型，0：条纹相、1：三氧化二铝棒状、2：三氧化二铝孔隙率
          this.showLength=true;
          this.showLayer=true;
          this.showNumber=false;
//          this.showGroup=false;
        }else if(this.typeValue==2){
          this.showLength=true;
          this.showLayer=false;
          this.showNumber=false;
//        this.showGroup=false;
        }else{
          this.showLength=true;
          this.showLayer=true;
          this.showNumber=true;
//          this.showGroup=true;
        }
        if(this.oldTypeValue!=null&&this.oldTypeValue!==this.typeValue){
          this.reset();
        }
        this.oldTypeValue=this.typeValue;
      }else{
        Modal.info({
          title: "提示",
          content: "必须选择样品编号！",
          centered: true
        });
      }
    },
    async analysis() {//统计分析
      let imageIds = this.$refs.eleImageList.getImageIds();
      if (imageIds && imageIds.length > 0) {
        //最多5次选择
        if (this.fileGroupDatas && this.fileGroupDatas.size > 4) {
          Modal.info({
            title: "提示",
            content: "最多允许选择5次！", //，请先删除某次选择！",
            centered: true
          });
          return;
        }
        let chartData = await getAnalysisData(imageIds,this.typeValue);
        //点击统计，计算选择次数，返回统计数据，显示统计图，第一个和第二个统计图复用。
        if (chartData) {
          this.selectionTimes++;
          let isFirst = false;
          if (this.chartLengthOption == null) {
            this.chartLengthOption = new Object();
            this.chartLengthOption.model = 1;
            this.chartLengthOption.yPercent = false;
            this.chartLengthOption.chartType = "bar";
            this.chartLengthOption.dataMap = new Map();

            if(this.typeValue==1){
              this.chartLengthOption.title = "三氧化二铝棒状平均长度";
              this.chartLengthOption.yTitle = "平均长度";
              this.chartLengthOption.xTitle = "批次";
              this.chartLengthOption.xNames = ["1~5"];
            }else if(this.typeValue==2){
              this.chartLengthOption.title = "三氧化二铝平均孔隙率";
              this.chartLengthOption.yTitle = "孔隙率";
              this.chartLengthOption.xTitle = "批次";
              this.chartLengthOption.xNames = ["1~5"];
            }else{
              this.chartLengthOption.isSaveImage=true;
              this.chartLengthOption.isShowSelLabel=true;
              this.chartLengthOption.title = "催化剂上MOS₂片晶的长度分布";

              this.chartLengthOption.dataType=0;
              this.chartLengthOption.imageFileIds=imageIds;

              this.chartLengthOption.yTitle = "%";
              this.chartLengthOption.xTitle = "MOS₂片晶的长度/nm";
              this.chartLengthOption.xNames = ["<3","3~4", "4~5", "5~6", ">6"];
            }
            isFirst = true;
          }
          if(this.typeValue==1){
            let arr=new Array();
            arr.push(chartData.average_length);
            this.chartLengthOption.dataMap.set("GY" + this.selectionTimes, arr);
          }else if(this.typeValue==2){
            let arr=new Array();
            arr.push(chartData.hole);
            this.chartLengthOption.dataMap.set("GY" + this.selectionTimes, arr);
          }else{
            this.chartLengthOption.dataMap.set("GY" + this.selectionTimes, chartData.arrayLength);
          }
          if (this.chartLayerOption == null) {
            this.chartLayerOption = new Object();
            this.chartLayerOption.model = 1;
            this.chartLayerOption.yPercent = false;
            this.chartLayerOption.dataMap = new Map();

            if(this.typeValue==1){
              this.chartLayerOption.title = "三氧化二铝棒状平均数量";
              this.chartLayerOption.yTitle = "平均数量";
              this.chartLayerOption.xTitle = "批次";
              this.chartLayerOption.xNames = ["1~5"];
              this.chartLayerOption.chartType = "bar";
            }else{
              this.chartLayerOption.isSaveImage=true;
              this.chartLayerOption.isShowSelLabel=true;
              this.chartLayerOption.title = "催化剂上MOS₂片晶的层数分布";

              this.chartLayerOption.dataType=1;
              this.chartLayerOption.imageFileIds=imageIds;

              this.chartLayerOption.yTitle = "%";
              this.chartLayerOption.xTitle = "MOS₂片晶层数";
              this.chartLayerOption.chartType = "line";
              this.chartLayerOption.xNames = ["1", "2", "3", "4", "5", "6", "7"];
            }
          }
          if(this.typeValue==1){
            let arr=new Array();
            arr.push(chartData.average_number);
            this.chartLayerOption.dataMap.set("GY" + this.selectionTimes,arr);
          }else{
            this.chartLayerOption.dataMap.set("GY" + this.selectionTimes, chartData.arrayLayer);
          }
          if (this.chartNumberOption == null) {
            this.chartNumberOption = new Object();
            this.chartNumberOption.isShowSelLabel=true;
            this.chartNumberOption.model = 1;
            this.chartNumberOption.title = "催化剂上MOS₂片晶的个数";

            this.chartNumberOption.dataType=2;
            this.chartNumberOption.imageFileIds=imageIds;

            this.chartNumberOption.yTitle = "MOS₂片晶的个数/1000nm²";
            this.chartNumberOption.yPercent = false;
            this.chartNumberOption.xTitle = "MOS₂片晶层数";
            this.chartNumberOption.chartType = "line";
            this.chartNumberOption.xNames = ["1", "2", "3", "4", "5", "6", "7"];
            this.chartNumberOption.isSaveImage=true;
            this.chartNumberOption.dataMap = new Map();
          }
          this.chartNumberOption.dataMap.set("GY" + this.selectionTimes, chartData.arrayNumber);
          if (this.fileGroupDatas == null) {
            this.fileGroupDatas = new Map();
          }
          this.fileGroupDatas.set("GY" + this.selectionTimes, "GY" + this.selectionTimes);
          if (isFirst) {
            this.showChart = true;
          } else {
            this.$forceUpdate();
            this.$refs.eleChartNumber.refreshChart(this.chartNumberOption.dataMap);
            this.$refs.eleChartLayer.refreshChart(this.chartLayerOption.dataMap);
            this.$refs.eleChartLength.refreshChart(this.chartLengthOption.dataMap);
          }
        }
      } else {
        if (!this.showImage) {
          Modal.info({
            title: "提示",
            content: "统计分析前，请查询图像列表，并选择图像（可以多选）！",
            centered: true
          });
        } else {
          Modal.info({
            title: "提示",
            content: "统计分析前，请选择图像（可以多选）！",
            centered: true
          });
        }
      }
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    async handleDropdownVisibleChange(open){
       if(open){
           this.files = await getFilesData(this.catalystValue, this.startValue, this.endValue,this.typeValue);
       }
    },
    handleChange(value) {
      this.fileValue = value;
    },
    handelDeleteFileGroup(key) {
      Modal.confirm({
        title: "确认操作",
        content: "确认要删除本次选择的数据吗？",
        centered: true,
        onOk: () => {
          this.chartLengthOption.dataMap.delete(key);
          this.chartLayerOption.dataMap.delete(key);
          this.chartNumberOption.dataMap.delete(key);

          this.$refs.eleChartNumber.refreshChart(this.chartNumberOption.dataMap);
          this.$refs.eleChartLayer.refreshChart(this.chartLayerOption.dataMap);
          this.$refs.eleChartLength.refreshChart(this.chartLengthOption.dataMap);

          this.fileGroupDatas.delete(key);
          if (this.fileGroupDatas.size == 0) {
            this.showChart = false;
            this.selectionTimes = 0;
            this.chartLengthOption = null;
            this.chartLayerOption = null;
            this.chartNumberOption = null;
            this.fileGroupDatas = null;
          }
          this.$forceUpdate();
        }
      });
    },
    handelUpdateFileGroup(key, value) {
      if(this.chartLengthOption.seriesNames==null){
        this.chartLengthOption.seriesNames=new Map();
      }
      this.chartLengthOption.seriesNames.set(key,value);

      if(this.chartLayerOption.seriesNames==null){
        this.chartLayerOption.seriesNames=new Map();
      }
      this.chartLayerOption.seriesNames.set(key,value);

      if(this.chartNumberOption.seriesNames==null){
        this.chartNumberOption.seriesNames=new Map();
      }
      this.chartNumberOption.seriesNames.set(key,value);

      this.$refs.eleChartNumber.refreshChart(this.chartNumberOption.dataMap);
      this.$refs.eleChartLayer.refreshChart(this.chartLayerOption.dataMap);
      this.$refs.eleChartLength.refreshChart(this.chartLengthOption.dataMap);

      this.fileGroupDatas.set(key,value);
      this.$forceUpdate();
    },
  }
}
</script>

<style scoped>
.chartDiv {
  margin: 10px 0;
}

.file-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  float: left;
}
.file-group a{
  margin:2px;
}
.ant-card {
  margin-bottom: 10px;
  width:100%;
}

.ant-card table{
  width:100%;
}
table, td, th {
  border-collapse: collapse;
  border: 1px solid black;
}

th {
  background: lightblue;
}

th, td {
  text-align: center;
  padding: 5px 10px;
  height: 30px;
}

</style>
